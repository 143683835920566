import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const TjDirectory = () => (
  <Layout>
    <SEO title='HQ Directory' />
    <h1>Tommy John HQ Directory</h1>
    <p>You have reached the Directory</p>
  </Layout>
)

export default TjDirectory
